import React from 'react';

import Footer from 'src/components/other/Footer';
import Image from 'src/components/ui/Image';

import { colours } from 'src/constants/colours';
import { breakpoints } from 'src/constants/breakpoints';
import { styledComponent, styledTag } from 'src/utils/styled';

interface ContainerProps {
    height?: string;
}

const Container = styledTag('div')<ContainerProps>`
    display: grid;
    grid-template-columns: 100%;
    height: ${(props) => props.height ?? '100vh'};
    @media (min-width: ${breakpoints.tabletLandscape}px) {
        grid-template-columns: 41rem 1fr;
    }
    @media (min-width: ${breakpoints.desktopLarge}px) {
        grid-template-columns: 1fr 1fr;
    }
`;

const ScrollContainer = styledTag('div')`
    position: relative;
    background-color: ${colours.white.main};
    overflow: auto;
`;

const PanelContainer = styledTag('div')`
    position: relative;
`;

const PanelTop = styledTag('div')<{ $isPanelTopSticky: boolean }>`
    ${({ $isPanelTopSticky }) => $isPanelTopSticky && `position: sticky;`}
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
`;

const PanelTopLeft = styledTag('div')`
    position: absolute;
    top: 3rem;
    left: 5.5rem;
`;

const PanelTopRight = styledTag('div')`
    position: absolute;
    top: 3rem;
    right: 5.5rem;
`;

const PanelContent = styledTag('div')<{ $panelContentPaddingTopInRem: number }>`
    ${({ $panelContentPaddingTopInRem }) => `padding: ${$panelContentPaddingTopInRem}rem 1rem 3rem 1rem;`}
    margin: 0 auto;
    @media (min-width: ${breakpoints.tabletPortrait}px) {
        ${({ $panelContentPaddingTopInRem }) => `padding: ${$panelContentPaddingTopInRem}rem 10% 3rem 10%;`}
    }
`;

const RightAreaContainer = styledTag('div')`
    display: none;
    width: 100%;
    height: 100%;
    position: relative;
    svg {
        max-width: 80%;
    }
    @media (min-width: ${breakpoints.tabletLandscape}px) {
        display: block;
    }
`;

const BackgroundImage = styledComponent(Image)`
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    @media (min-width: ${breakpoints.tabletLandscape}px) {
        display: block;
    }
`;

interface IProps {
    panelTop?: React.ReactElement | React.ReactElement[] | null;
    panelTopRight?: React.ReactElement | React.ReactElement[] | null;
    panelTopLeft?: React.ReactElement | React.ReactElement[] | null;
    panelContent?: React.ReactElement | React.ReactElement[];
    rightArea?: React.ReactElement | React.ReactElement[];
    backgroundImage?: string;
    height?: string;
    isPanelTopSticky?: boolean;
    panelContentPaddingTopInRem?: number;
}

const SplitLayout: React.FC<IProps> = ({
    panelTop,
    panelTopRight,
    panelTopLeft,
    panelContent,
    backgroundImage = `${process.env.NEXT_PUBLIC_SITE_URL}/assets/images/login-bg.jpg`,
    rightArea,
    height,
    isPanelTopSticky = true,
    panelContentPaddingTopInRem = 9,
}) => (
    <>
        <Container height={height}>
            <ScrollContainer>
                <PanelContainer>
                    {panelTop && <PanelTop $isPanelTopSticky={isPanelTopSticky}>{panelTop}</PanelTop>}
                    {panelTopLeft && <PanelTopLeft>{panelTopLeft}</PanelTopLeft>}
                    {panelTopRight && <PanelTopRight>{panelTopRight}</PanelTopRight>}
                    <PanelContent $panelContentPaddingTopInRem={panelContentPaddingTopInRem}>
                        {panelContent}
                    </PanelContent>
                </PanelContainer>
            </ScrollContainer>
            <RightAreaContainer>
                {rightArea ?? <BackgroundImage src={backgroundImage} alt="right background image" />}
            </RightAreaContainer>
        </Container>
        <Footer />
    </>
);

export default SplitLayout;
