import React, { useState, useEffect, useMemo } from 'react';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useTranslation } from 'next-i18next';

import { DialogActions, DialogContent } from '@mui/material';
import Box from '@mui/material/Box';

import SplitLayout from 'src/components/other/SplitLayout';
import LoginForm from 'src/components/auth/LoginForm';
import AuthRightArea from 'src/components/auth/AuthRightArea';
import Logo from 'src/components/other/Logo';
import Button from 'src/components/ui/Button';
import Dialog from 'src/components/ui/Dialog';
import DialogTitle from 'src/components/ui/DialogTitle';
import Typography from 'src/components/ui/Typography';
import Loader from 'src/components/ui/Loader';

import useAlerts from 'src/hooks/useAlerts';
import useUser from 'src/hooks/useUser';
import useAppRouter, { useManagedRouter } from 'src/hooks/useAppRouter';
import useAuthActions from 'src/hooks/useAuthActions';

import { useRecoverUserMutation } from 'src/hooks/__generated__/queries';
import { DeletedReason } from 'src/types/__generated__/graphql';
import { routePathHome } from 'src/constants/router';
import { styledComponent } from 'src/utils/styled';

const StyledDialog = styledComponent(Dialog)`
    .MuiDialogContent-root {
        padding: 0 1.5rem;
    }
    .MuiDialogActions-root,
    .MuiDialogTitle-root {
        padding: 1.5rem;
    }
`;

StyledDialog.defaultProps = {
    maxWidth: 'xs',
    fullWidth: true,
};

const SigninPage: PageComponent = () => {
    const currentUser = useUser();
    const { t } = useTranslation(['common']);
    const { addAlert } = useAlerts();
    const [recoverUser, { loading }] = useRecoverUserMutation();
    const [open, setOpen] = useState(false);
    const router = useAppRouter();
    const { signOut } = useAuthActions();
    const returnUrl = useReturnUrl();

    useEffect(() => {
        if (currentUser?.deletedAt) {
            setOpen(true);
        }
    }, [currentUser?.deletedAt]);

    const handleClose = async () => {
        if (!loading) {
            await signOut();
            setOpen(false);
        }
    };

    const handleSubmit = async () => {
        try {
            const { data } = await recoverUser({
                refetchQueries: ['CurrentUser'],
                awaitRefetchQueries: true,
            });
            if (data?.recoverUser) {
                await router.push(routePathHome);
            }
        } catch (error: unknown) {
            addAlert({
                type: 'error',
                message: String(error),
            });
        }
    };

    let titleKey = 'common:reactivateAccountTitle';
    let textKey = 'common:reactivateAccountText';
    let okButtonHandler = handleSubmit;

    if (currentUser?.deletedReason === DeletedReason.DeletedByAdmin) {
        titleKey = 'common:accountDisabledTitle';
        textKey = 'common:accountDisabledText';
        okButtonHandler = handleClose;
    }

    return (
        <>
            <SplitLayout
                isPanelTopSticky={false}
                panelTop={
                    router.query.isEmailVerify ? (
                        <Box
                            width="100%"
                            display="flex"
                            justifyContent="center"
                            flexDirection="row"
                            mt="3rem"
                            position="absolute"
                        >
                            <Logo />
                        </Box>
                    ) : undefined
                }
                panelTopLeft={router.query.isEmailVerify ? undefined : <Logo />}
                panelContent={<LoginForm isEmailVerify={Boolean(router.query.isEmailVerify)} returnUrl={returnUrl} />}
                rightArea={<AuthRightArea isPro={false} />}
            />
            <StyledDialog open={open} onClose={handleClose}>
                <DialogTitle onClose={handleClose}>
                    <Typography variant="h5">{t(titleKey)}</Typography>
                </DialogTitle>
                {loading ? (
                    <Loader />
                ) : (
                    <>
                        <DialogContent>
                            <Typography variant="body1">{t(textKey)}</Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button variant="outlined" onClick={handleClose}>
                                {t('common:modal.cancel')}
                            </Button>
                            <Button onClick={okButtonHandler}>{t('common:modal.ok')}</Button>
                        </DialogActions>
                    </>
                )}
            </StyledDialog>
        </>
    );
};

const useReturnUrl = () => {
    const { router } = useManagedRouter();
    const returnUrl = useMemo(() => {
        if (router.query.returnUrl && typeof router.query.returnUrl === 'string') {
            const decodedReturnUrl = decodeURIComponent(router.query.returnUrl);
            // We check if returnUrl starts with a slash to avoid redirect to external URLs
            if (decodedReturnUrl.startsWith('/')) {
                return decodeURIComponent(router.query.returnUrl);
            }
        }

        return undefined;
    }, [router.query.returnUrl]);

    return returnUrl;
};

export const getServerSideProps = async ({ locale }: { locale: string }) => ({
    props: {
        ...(await serverSideTranslations(locale, ['common'])),
        SEOProps: {
            pageTitleTranslation: {
                key: 'common:seo.login',
            },
        },
    },
});

export default SigninPage;
