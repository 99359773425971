import React from 'react';

import Box from '@mui/material/Box';

import { styledComponent } from 'src/utils/styled';

const RightArea = styledComponent(Box)<{
    $isPro: boolean;
}>`
    background: ${({ $isPro }) =>
        $isPro
            ? `center / cover no-repeat url('${process.env.NEXT_PUBLIC_SITE_URL}/assets/images/pro-user-image.png')`
            : `center / cover no-repeat url('${process.env.NEXT_PUBLIC_SITE_URL}/assets/images/non-pro-user-image.png')`};
`;

RightArea.defaultProps = {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
};

type Props = {
    isPro?: boolean;
};

const AuthRightArea: React.FC<Props> = ({ isPro = true }) => <RightArea $isPro={isPro} />;

export default AuthRightArea;
